@import url(../fonts/iconmoon/style.css);
*, *:before, *:after {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  position: relative;
}

img {
  height: auto;
  max-width: 100%;
}

a, a:link, a:visited, a:hover, a:active, a:focus {
  text-decoration: none;
  outline: none;
  white-space: nowrap;
}

a {
  color: inherit;
  cursor: pointer;
}

input {
  outline: none;
  font-family: inherit;
  border: none;
}

button {
  border: none;
  outline: none;
  background: transparent;
}

ul li {
  list-style: none;
}

@keyframes sliderContentHide {
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes sliderContentShow {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes slide {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes hidden {
  0% {
    z-index: 2;
  }
  100% {
    z-index: 2;
  }
}

*::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #e7e7e7;
  border-radius: 0;
}

*::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(163, 163, 163, 0.8);
}

*::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 0;
  background: rgba(163, 163, 163, 0.9);
  transition: background 0.2s ease;
}

*::-webkit-scrollbar-thumb:hover {
  background: #a3a3a3;
}

/*ff*/
* {
  scrollbar-color: #a3a3a3 #e7e7e7;
  scrollbar-width: thin;
}

@-moz-document url-prefix(http://),url-prefix(https://) {
  scrollbar {
    -moz-appearance: none !important;
    background: lime !important;
  }
  thumb, scrollbarbutton {
    -moz-appearance: none !important;
    background-color: blue !important;
  }
  thumb:hover, scrollbarbutton:hover {
    -moz-appearance: none !important;
    background-color: red !important;
  }
  scrollbarbutton {
    display: none !important;
  }
  scrollbar[orient="vertical"] {
    min-width: 15px !important;
  }
}

::-webkit-input-placeholder {
  color: #C4C4C4;
  font-family: 'Montserrat Alternates',sans-serif;
}

::-moz-placeholder {
  color: #C4C4C4;
  font-family: 'Montserrat Alternates',sans-serif;
}

:-moz-placeholder {
  color: #C4C4C4;
  font-family: 'Montserrat Alternates',sans-serif;
}

:-ms-input-placeholder {
  color: #C4C4C4;
  font-family: 'Montserrat Alternates',sans-serif;
}

a, label {
  -webkit-tap-highlight-color: transparent;
}

body {
  -webkit-text-size-adjust: none;
}

/*input[type=text], input[type=submit], textarea{
 // -webkit-appearance: none;
} 
*/
body {
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 14px;
  line-height: 1.7;
  color: #111111;
}

.basket_update_info {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  color: #FF7A1B;
  z-index: 100500;
  padding: 20px 25px;
  font-weight: bold;
  font-size: 18px;
  top: -90px;
  transition: .4s;
  border: 1px solid #FF7A1B;
  white-space: nowrap;
  text-align: center;
}

.basket_update_info.active {
  top: 50px;
}

h1,
.h1 {
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: bold;
  color: #111111;
  font-size: 36px;
  line-height: 1.7;
  margin: 15px 0 15px;
}

@media (max-width: 991px) {
  h1,
  .h1 {
    font-size: 30px;
  }
}

@media (max-width: 767px), (max-height: 460px) {
  h1,
  .h1 {
    font-size: 24px;
  }
}

@media (max-width: 540px) {
  h1,
  .h1 {
    font-size: 18px;
  }
}

h2,
.h2 {
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: bold;
  color: #111111;
  font-size: 24px;
  line-height: 1.4;
}

@media (max-width: 767px), (max-height: 460px) {
  h2,
  .h2 {
    font-size: 18px;
  }
}

@media (max-width: 540px) {
  h2,
  .h2 {
    font-size: 16px;
  }
}

h3,
.h3 {
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: bold;
  color: #111111;
  font-size: 18px;
  line-height: 1.4;
  white-space: normal;
}

@media (max-width: 767px), (max-height: 460px) {
  h3,
  .h3 {
    font-size: 16px;
  }
}

@media (max-width: 540px) {
  h3,
  .h3 {
    font-size: 14px;
  }
}

p {
  font-family: 'Montserrat Alternates', sans-serif;
  line-height: 1.7;
  margin: 20px 0;
}

.hidden {
  display: none;
}

label {
  cursor: pointer;
}

.max-767 {
  display: none;
}

@media (max-width: 767px), (max-height: 460px) {
  .max-767 {
    display: block;
  }
}

.max-991 {
  display: none;
}

@media (max-width: 991px), (max-height: 460px) {
  .max-991 {
    display: block;
  }
}

.iconmoon, .checkbox__input + label:before, .rating__star {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nowrap {
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.for-mobile {
  display: none;
}

@media (max-width: 767px), (max-height: 460px) {
  .for-mobile {
    display: block;
  }
}

.scroll {
  overflow: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.img__full {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img__center {
  object-position: center;
}

.img__top {
  object-position: top;
}

.img__contain {
  object-fit: contain;
  max-height: 100%;
}

.btn {
  height: 36px;
  width: 185px;
  line-height: 36px;
  border-radius: 18px;
  font-family: montserrat, sans-serif;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
}

.btn__full {
  background: #FF7A1B;
  position: relative;
  color: #fff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
  transition: all 0.3s;
  z-index: 1;
}

.btn__full:active {
  background: #FF6D04;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.07);
}

.btn__full:focus {
  background: #FF6D04;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.07);
}

.btn__icon {
  height: 52px;
  width: 52px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn__icon-full {
  background: #FF7A1B;
  position: relative;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
  color: #fff;
  transition: all 0.3s;
  z-index: 1;
}

.btn__icon-full:before {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: -1;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #FF7A1B 50%, #fff 166.34%);
  transition: all 0.3s;
}

.btn__icon-full:hover {
  background: #FF7A1B;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.07);
}

.btn__icon-full:hover:before {
  opacity: 1;
}

.btn__icon-full:active {
  background: #FF6D04;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.07);
}

.btn__icon-full:active:before {
  opacity: 0;
}

.btn__icon-full:focus {
  background: #FF6D04;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.07);
}

.btn__icon-full:focus:before {
  opacity: 0;
}

.btn__icon-empty {
  background-color: #fff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
  color: #FF7A1B;
}

.btn__menu {
  width: 27px;
  cursor: pointer;
  z-index: 9;
  margin-left: 10px;
}

.btn__menu:hover .line:after {
  width: 50%;
}

.btn__menu:hover .line:last-child {
  transform: translateX(90%);
}

.btn__menu:hover .line:last-child:after {
  width: 65%;
}

@media (pointer: coarse) {
  .btn__menu:hover .line:after {
    width: 0;
  }
  .btn__menu:hover .line:last-child {
    transform: translateX(0%);
  }
  .btn__menu:hover .line:last-child:after {
    width: 0;
  }
}

.btn__menu.btn__menu-open .line:after {
  width: 50%;
}

.btn__menu.btn__menu-open .line:last-child {
  transform: translateX(90%);
}

.btn__menu.btn__menu-open .line:last-child:after {
  width: 65%;
}

@media (max-width: 767px), (max-height: 460px) {
  .btn__menu {
    position: fixed;
    top: 25px;
    right: 20px;
  }
}

.btn__menu .line {
  width: 100%;
  height: 2px;
  background-color: #FF7A1B;
  transition: 0.25s;
  margin-bottom: 7px;
}

.btn__menu .line:last-child {
  width: 52%;
}

.btn__menu .line:after {
  content: '';
  background: #fff;
  transition: .5s;
  opacity: .8;
  width: 0;
  height: 2px;
  display: block;
}

.link {
  color: #FF7A1B;
  transition: all 0.3s;
}

.link:hover {
  transform: scale(1.1);
}

.text {
  color: #111111;
  font-size: 14px;
}

.text-notify {
  color: #17B057;
}

.text-secondary {
  color: #656565;
  font-size: 11px;
  font-weight: 200;
  line-height: 20px;
}

.text-light {
  color: #656565;
  font-weight: 200;
}

.text-lg {
  font-size: 18px;
  display: inline-block;
}

.list {
  margin-bottom: 20px;
}

.list__item {
  margin-bottom: 20px;
  padding-left: 15px;
}

.list__item:before {
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #FF7A1B;
  position: absolute;
  top: 7px;
  left: 0;
}

.hr-line {
  margin: 20px -10px;
  width: 100%;
  height: 1px;
  background-color: #D7DEED;
}

.hr-line-s {
  width: 92px;
  margin-bottom: 20px;
  height: 1px;
  background-color: #D7DEED;
}

.tabs {
  margin: 0 -10px;
  display: flex;
}

@media (max-width: 767px), (max-height: 460px) {
  .tabs {
    flex-wrap: wrap;
  }
}

.tabs__content {
  margin: 0 -10px;
}

@media (max-width: 767px), (max-height: 460px) {
  .tabs__content {
    margin: 0 -20px;
  }
}

.tab {
  width: 100%;
  cursor: pointer;
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.2);
  cursor: pointer;
  color: #C4C4C4;
  transition: all 0.5s;
}

@media (max-width: 767px) {
  .tab {
    display: none;
  }
}

.tab-active {
  color: #FF7A1B;
  border-color: #FF7A1B;
}

.tab-mobil {
  display: none;
}

@media (max-width: 767px) {
  .tab-mobil {
    display: block;
  }
}

.tab-mobil.tab-active {
  color: inherit;
  border-color: #656565;
}

.tab__content {
  padding: 40px 10px;
  overflow: hidden;
  transition: opacity 0.5s, width 0 0.5s;
}

@media (max-width: 767px) {
  .tab__content {
    display: none;
    padding: 40px 20px;
  }
}

.tab__icon {
  font-size: 22px;
  margin-bottom: 15px;
}

@media (max-width: 767px), (max-height: 460px) {
  .tab__icon {
    margin-bottom: 0;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  padding: 20px 10px;
  width: 140px;
}

@media (max-width: 767px), (max-height: 460px) {
  .header {
    width: 100%;
    height: 70px;
    background: #fff;
    padding: 11px 20px 11px;
    display: flex;
    align-items: center;
  }
}

.header__logo {
  margin-bottom: 40px;
  width: auto;
  z-index: 5;
  display: inline-block;
}

@media (max-width: 767px), (max-height: 460px) {
  .header__logo {
    margin-bottom: 0;
  }
}

.logo__img {
  display: block;
}

.menu {
  width: 0;
  min-width: 0;
  overflow: hidden;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.8s;
  z-index: 8;
}

@media (max-width: 767px), (max-height: 460px) {
  .menu {
    width: 100%;
    height: 0;
  }
}

.menu.menu__open {
  width: 50%;
  min-width: 450px;
  transition: all 0.8s;
}

@media (max-width: 767px), (max-height: 460px) {
  .menu.menu__open {
    width: 100%;
    min-width: unset;
    height: 100%;
    background-color: #fff;
  }
}

.menu.menu__open .menu__list {
  opacity: 1;
  transition: opacity 0.5s;
}

.menu.menu__open .menu__overlay {
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s 0.3s;
}

.menu__overlay {
  width: 0;
  opacity: 0;
  height: 100%;
  position: fixed;
  z-index: 0;
  background-color: rgba(116, 88, 68, 0.61);
  transition: opacity 0.5s, width 0s 0.5s;
}

@media (max-width: 767px), (max-height: 460px) {
  .menu__overlay {
    display: none;
  }
}

.menu__wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #FCFCFC;
  z-index: 2;
}

@media (max-width: 767px), (max-height: 460px) {
  .menu__wrap {
    overflow: auto;
    padding: 0 20px 40px;
    margin-top: 70px;
    height: calc(100% - 70px);
    -webkit-overflow-scrolling: touch;
  }
}

.menu__list {
  margin-left: 190px;
  margin-top: 60px;
  color: #111111;
  transition: all 0.1s;
  opacity: 0;
  transition: opacity 0.5s 0.5s;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.menu__list ::selection {
  background-color: transparent;
}

.menu__list::-moz-selection {
  background-color: transparent;
}

@media (min-width: 768px) and (min-height: 461px) {
  .menu__list:hover {
    color: #dcddde;
  }
}

@media (max-width: 991px) {
  .menu__list {
    margin-left: 170px;
  }
}

@media (max-height: 700px) {
  .menu__list {
    margin-top: 20px;
  }
}

@media (max-width: 767px), (max-height: 460px) {
  .menu__list {
    margin-left: 0;
    margin-top: 0;
    color: #656565;
  }
}

.menu__sublist {
  position: fixed;
  top: 70px;
  left: 50%;
  width: 33%;
  padding-left: 60px;
  color: #111111;
  opacity: 0;
  top: -100%;
  transition: color 0.1s, opacity 0.5s, top 0s 0.5s;
  z-index: -1;
}

@media (max-width: 900px) {
  .menu__sublist {
    left: 450px;
    padding-left: 20px;
  }
}

@media (max-width: 900px) {
  .menu__sublist {
    left: 450px;
    padding-left: 20px;
  }
}

@media (min-width: 768px) and (min-height: 461px) {
  .menu__sublist:hover {
    z-index: 3;
    transition: color 0.1s, opacity 0.5s 0.5s, top 0s 0s;
  }
  .menu__sublist:hover + .menu__item-lg {
    color: #111111;
    padding-left: 10px;
  }
}

.menu__sublist:before {
  content: "";
  width: 33%;
  height: 100%;
  background: #fff;
  opacity: 0;
  display: block;
  position: fixed;
  top: -100%;
  left: 50%;
  transition: opacity 0.5s, top 0s 0.5s;
}

@media (max-width: 900px) {
  .menu__sublist:before {
    left: 450px;
  }
}

@media (max-width: 767px), (max-height: 460px) {
  .menu__sublist:before {
    display: none;
  }
}

.menu__back {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.5s, height 0s 0.5s;
  color: #FF7A1B;
  display: flex;
  align-items: center;
}

.menu__parent {
  transition: height 0.5s;
}

@media (min-width: 768px) and (min-height: 461px) {
  .menu__parent:hover .menu__sublist, .menu__parent:focus .menu__sublist {
    top: 70px;
    opacity: 1;
    z-index: 3;
    transition: color 0.1s, opacity 0.5s, top 0s 0s;
  }
  .menu__parent:hover .menu__sublist:before, .menu__parent:focus .menu__sublist:before {
    top: 0;
    opacity: 0.85;
    transition: color 0.1s, opacity 0.5s, top 0s 0s;
  }
}

@media (max-height: 700px) {
  .menu__parent:hover .menu__sublist, .menu__parent:focus .menu__sublist {
    top: 20px;
  }
}

@media (max-width: 767px) {
  .menu__parent:hover, .menu__parent:focus {
    color: #111111;
  }
}

.menu__item {
  transition: all 0.3s;
  display: block;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.menu__item-lg {
  font-size: 18px;
  font-weight: bold;
  padding: 30px 0;
}

@media (max-height: 700px) {
  .menu__item-lg {
    padding: 20px 0;
  }
}

@media (max-height: 560px) {
  .menu__item-lg {
    padding: 10px 0;
  }
}

@media (max-width: 767px), (max-height: 460px) {
  .menu__item-lg {
    padding: 15px 0;
  }
}

@media (min-width: 767px) and (min-height: 461px) {
  .menu__item-lg:hover {
    color: #111111;
    padding-left: 10px;
  }
}

.menu__item-md {
  padding: 15px 0;
  color: #656565;
  display: block;
}

.menu__item-md:hover {
  color: #111111;
}

.menu__search {
  display: none;
}

@media (max-width: 767px), (max-height: 460px) {
  .menu__search {
    display: block;
    margin-bottom: 25px;
  }
}

.menu__seraching {
  position: absolute;
  top: 10px;
  left: 1px;
  z-index: 2;
}

.menu__input {
  border-bottom: 1px solid rgba(196, 196, 196, 0.5);
  height: 35px;
  width: 100%;
  padding-left: 27px;
}

@media (max-width: 767px), (max-height: 460px) {
  .basket_update_info {
    padding: 15px 45px;
    font-size: 16px;
  }
  .basket_update_info.active {
    top: 90px;
  }
  .menu__parent-open .menu__sublist {
    max-height: 500px;
    margin-top: 20px;
    z-index: 99;
    transition: all 0.8s;
  }
  .menu__list-open .menu__sublist {
    transition: all 0.8s;
  }
  .menu__list-open .menu__parent-open .menu__sublist {
    transition: all 0.8s 0.5s;
  }
  .menu__sublist {
    position: relative;
    padding: 0;
    width: 100%;
    top: unset !important;
    height: auto;
    overflow: hidden;
    left: unset;
    opacity: 1;
    color: #656565;
    padding-left: 40px;
    max-height: 0;
    transition: all 0.8s;
  }
  .menu__list + .menu__list {
    margin-top: 40px;
  }
  a.menu__item:hover {
    color: #FF7A1B;
  }
}

.lang {
  display: flex;
  margin-left: 190px;
  margin-top: auto;
  margin-bottom: 20px;
}

@media (max-width: 767px), (max-height: 460px) {
  .lang {
    margin-left: 0;
  }
}

.lang__item {
  margin-right: 20px;
}

.lang__link {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  font-weight: 200;
  color: #C4C4C4;
  display: block;
}

@media (max-width: 767px), (max-height: 460px) {
  .lang__link {
    text-align: left;
  }
}

.lang__link:hover {
  color: #FF7A1B;
}

.lang__active {
  color: #FF7A1B;
}

#btnSearch {
  position: fixed;
  top: 20px;
  right: 20px;
}

@media (max-width: 1199px) {
  #btnSearch {
    right: 10px;
  }
}

#formSearch {
  position: fixed;
  top: 20px;
  right: 20px;
}

@media (max-width: 1199px) {
  #formSearch {
    right: 10px;
  }
}

@media (max-width: 767px), (max-height: 460px) {
  #formSearch {
    display: none;
  }
}

.search__form {
  width: 50px;
  max-width: calc(100% - 20px);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  border-radius: 25px;
  transition: all 0.5s;
}

.search__form-open {
  width: 363px;
}

.search__input {
  height: 50px;
  width: 100%;
  padding: 0 50px 0 40px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
  border-radius: 25px;
}

#btnBasket {
  position: fixed;
  top: 90px;
  right: 20px;
  z-index: 6;
}

@media (max-width: 1199px) {
  #btnBasket {
    right: 10px;
  }
}

@media (max-width: 767px), (max-height: 460px) {
  #btnBasket {
    top: 9px;
    right: 65px;
  }
}

.btnBasket__count {
  background-color: #FF0000;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
  text-align: center;
  line-height: 16px;
  bottom: 9px;
  right: 9px;
  position: absolute;
}

#btnCatalog {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9;
}

@media (max-width: 1199px) {
  #btnCatalog {
    right: 10px;
  }
}

@media (max-width: 991px), (max-height: 460px) {
  #btnCatalog {
    display: none;
  }
}

.btn-ctalog__img {
  width: 16px;
}

.sidebar {
  width: 140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  transition: all 0.5s 0s;
}

@media (max-width: 767px), (max-height: 460px) {
  .sidebar {
    position: relative;
    top: unset;
    left: unset;
    width: 100%;
    height: auto;
    padding: 10px 20px;
  }
}

.sidebar__block {
  padding: 10px 0;
  margin-top: 30px;
}

@media (max-width: 767px), (max-height: 460px) {
  .sidebar__block {
    margin-top: 10px;
  }
}

.sidebar__block:before {
  content: '';
  display: block;
  width: 32px;
  height: 1px;
  background-color: #FF7A1B;
  position: absolute;
  top: 0;
  left: -20px;
  opacity: 0.4;
}

@media (max-width: 767px), (max-height: 460px) {
  .sidebar__block:before {
    display: none;
  }
}

.sidebar__show {
  z-index: 8;
  opacity: 1;
  height: 100%;
  transition: all 0.5s 0.5s;
}

@media (max-width: 767px), (max-height: 460px) {
  .sidebar__show {
    z-index: 1;
  }
}

.sidebar__link {
  margin-bottom: 10px;
  display: inline-block;
  transition: all 0.1s;
  color: #111;
}

@media (max-width: 767px), (max-height: 460px) {
  .sidebar__link {
    margin-right: 40px;
  }
}

@media (max-width: 425px) {
  .sidebar__link {
    margin-right: 15px;
  }
}

.sidebar__link:hover {
  transform: scale(1.05, 1.05);
  color: #FF7A1B;
}

.phone__text {
  color: #C4C4C4;
  font-size: 11px;
  margin-bottom: 20px;
}

.main {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 7;
}

@media (max-width: 767px), (max-height: 460px) {
  .main {
    flex-direction: column-reverse;
  }
}

.main__slider {
  width: 50%;
}

@media (max-width: 767px), (max-height: 460px) {
  .main__slider {
    width: 100%;
  }
  .main__slider#mainSlider {
    height: 250px;
  }
  .main__slider#mainImgSlider {
    height: calc(100% - 250px);
  }
}

@media (max-width: 540px), (max-height: 460px) {
  .main__slider#mainSlider {
    height: 180px;
  }
  .main__slider#mainImgSlider {
    height: calc(100% - 180px);
  }
}

.main__item {
  display: flex;
  width: 100%;
  height: 100%;
}

.main__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

@media (max-width: 767px), (max-height: 460px) {
  .main__content {
    padding-bottom: 50px;
  }
}

.main__title {
  text-align: center;
  width: 600px;
  max-width: 95%;
  margin-bottom: 30px;
  animation-name: sliderContentHide;
  animation-duration: .4s;
  animation-fill-mode: both;
  opacity: 0;
  line-height: 1.7;
}

@media (max-width: 767px) {
  .main__title {
    margin-top: auto;
    margin-bottom: 0;
  }
}

.main__text {
  width: 450px;
  max-width: 95%;
  text-align: center;
  margin-bottom: 60px;
  animation-name: sliderContentHide;
  animation-duration: .4s;
  animation-fill-mode: both;
  opacity: 0;
}

@media (max-width: 767px) {
  .main__text {
    margin-top: 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 540px), (max-height: 460px) {
  .main__text {
    display: none;
  }
}

.main__img {
  width: 100%;
  height: 100%;
}

@media (max-width: 767px), (max-height: 460px) {
  .main__link {
    margin-top: auto;
  }
}

.main .slick-vertical .slick-slide {
  border: none;
}

.main .slick-list,
.main .slick-track,
.main .slick-vertical .slick-slide {
  height: 100% !important;
}

.main .slick-dots {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  text-align: center;
}

.main .slick-dots li {
  display: inline-flex;
  cursor: pointer;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  vertical-align: bottom;
}

.main .slick-dots li button {
  color: transparent;
  width: 5px;
  height: 5px;
  cursor: pointer;
  border-radius: 50px;
  background-color: #FF7A1B;
  transition: all 0.5s;
}

.main .slick-dots li.slick-active button {
  width: 10px;
  height: 10px;
}

.slick-active .main__text,
.slick-active .main__title {
  animation-name: sliderContentShow;
  animation-delay: .2s;
}

.slider__item {
  height: 100%;
}

.slick-list,
.slick-track,
.slick-slide,
.slick-slide > div {
  height: 100%;
}

.slick-vertical .slick-slide {
  border: none;
}

.slick-track,
.slick-list {
  -webkit-perspective: 2000;
  -webkit-backface-visibility: hidden;
}

.fancybox-container {
  z-index: 99;
}

.fancybox-navigation {
  height: 100%;
}

.content {
  margin-left: 140px;
  width: calc(100% - 140px);
  padding-right: 20px;
  color: #656565;
}

@media (max-width: 767px), (max-height: 460px) {
  .content {
    margin-left: 0;
    padding-right: 0;
    width: 100%;
    margin-top: 70px;
    height: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.content__header {
  padding: 20px 40px 40px;
}

@media (max-width: 767px), (max-height: 460px) {
  .content__header {
    padding: 20px 20px 15px;
  }
}

.vertical {
  overflow: hidden;
  height: 100vh;
  display: flex;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 767px), (max-height: 460px) {
  .vertical {
    flex-wrap: wrap;
    overflow: auto;
    height: auto;
  }
}

.vertical__wrap {
  width: 100%;
  padding: 0 20px;
  overflow: auto;
  flex-shrink: 2;
}

@media (max-width: 991px) {
  .vertical__wrap {
    padding-left: 0;
  }
}

@media (max-width: 767px), (max-height: 460px) {
  .vertical__wrap {
    padding-left: 20px;
  }
}

.vertical__bg {
  width: auto;
  height: 100%;
  flex-shrink: 1;
  display: flex;
}

@media (max-width: 767px), (max-height: 460px) {
  .vertical__bg {
    height: auto;
    width: 100%;
    flex-wrap: wrap;
  }
}

.vertical__bg .slick-dots {
  padding-right: 20px;
  list-style: none;
  margin: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 991px) {
  .vertical__bg .slick-dots {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 7;
  }
}

@media (max-width: 767px), (max-height: 460px) {
  .vertical__bg .slick-dots {
    position: relative;
    margin-top: 13px;
    bottom: unset;
    left: unset;
  }
}

.vertical__bg .slick-dots li {
  width: 7.5vw;
  height: 10vw;
  margin-bottom: 20px;
  cursor: pointer;
  cursor: pointer;
}

@media (max-width: 991px) {
  .vertical__bg .slick-dots li {
    width: auto;
    height: auto;
    padding: 7px;
    margin: 0px;
    display: inline-block;
    transition: all 0.3s;
  }
  .vertical__bg .slick-dots li.slick-active {
    margin: -3px;
  }
}

.vertical__bg .slick-dots img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.vertical__bg-img {
  width: 45%;
  width: calc(100vw / 12 * 5 - 10px);
  padding-right: 20px;
  flex-shrink: 1;
  height: 100%;
  display: flex;
}

@media (max-width: 1024px) {
  .vertical__bg-img {
    width: calc(100vw / 12 * 4 - 10px);
  }
}

@media (max-width: 767px), (max-height: 460px) {
  .vertical__bg-img {
    width: 100%;
    height: calc(100vh - 190px);
    min-height: 100vw;
    padding-right: 0;
  }
}

.vertical__content {
  padding: 20px 60px 40px 20px;
}

@media (max-width: 1199px) {
  .vertical__content {
    padding-right: 40px;
    padding-left: 0;
  }
}

@media (max-width: 767px), (max-height: 460px) {
  .vertical__content {
    padding: 10px 0 20px;
  }
}

.vertical__gallery {
  width: 9%;
  width: calc(100vw / 12 - 20px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex-shrink: 0;
}

.vertical__item {
  margin-bottom: 20px;
  cursor: pointer;
  width: 100%;
  height: calc((100vw / 12 - 20px) * 1.3);
}

.vertical__slider {
  height: 100%;
}

.btn__zoom {
  display: none;
}

@media (max-width: 991px) {
  .btn__zoom {
    display: flex;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 7;
  }
}

@media (max-width: 767px) {
  .btn__zoom {
    top: unset;
    bottom: 20px;
  }
}

.btn__zoom .icon-del {
  transform: rotate(45deg);
  transition: all 0.5s;
  display: block;
}

.zooming .btn__zoom .icon-del {
  transform: rotate(180deg);
}

.btn__zoom-active .icon-del {
  transform: rotate(225deg);
}

.hoverzoom,
.vertical__slider,
.bg-img__item,
.slick-vertical .slick-list,
.slick-vertical .slick-track,
.slick-vertical .slick-slide,
.slick-vertical .slick-slide > div,
.bg-img__item {
  width: 100%;
}

.slick-vertical .slick-list,
.slick-vertical .slick-track,
.slick-vertical .slick-slide {
  height: 100% !important;
}

.slick-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #FF7A1B;
  transition: all 0.3s;
}

.slick-active .slick-dot {
  width: 10px;
  height: 10px;
}

.vertical__slid {
  height: 100%;
}

.bg-img__item {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: cenetr;
}

@media (max-width: 767px), (max-height: 460px) {
  .bg-img__item {
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }
}

.bg-img__item.bg-img__item-active {
  z-index: 3;
  -webkit-animation: slide 1s 1;
}

.bg-img__item:not(.bg-img__item-active) {
  -webkit-animation: hidden 1s 1;
}

.breadcrumb {
  font-size: 13px;
  color: #B5B5B5;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

@media (max-width: 540px) {
  .breadcrumb {
    display: none;
  }
}

.card {
  position: relative;
  padding: 10px;
}

.card__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .card__row {
    margin: 0 10px;
    margin-bottom: 0;
  }
}

.card__name {
  position: absolute;
  display: inline-block;
  bottom: 40px;
  left: 0;
  padding: 0 10px;
  line-height: 35px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.65);
  font-size: 15px;
}

@media (max-width: 767px) {
  .card__name {
    font-size: 14px;
    padding: 0 5px;
  }
}

.card__price {
  position: absolute;
  display: inline-block;
  bottom: 5px;
  left: 0;
  padding: 0 10px;
  line-height: 35px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.65);
  font-size: 15px;
}

@media (max-width: 767px) {
  .card__price {
    font-size: 14px;
  }
}

.card__price-old {
  text-decoration: line-through;
  margin-right: 12px;
  font-size: 12px;
}

.card__price-new {
  color: #FF0000;
}

.card__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.5s;
}

.card__link {
  display: block;
  height: 100%;
  overflow: hidden;
}

.card__link:hover .card__img {
  width: calc(100% + 10px);
  height: calc(100% + 10px);
}

.card__delete {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background: #FFFFFF;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
  border-radius: 40px;
  z-index: 2;
  color: #FF7A1B;
  text-align: center;
  line-height: 22px;
  font-size: 9px;
  cursor: pointer;
}

@media (max-width: 767px), (max-height: 460px) {
  .card__delete {
    right: unset;
    left: 33.33%;
    margin-left: -15px;
  }
}

@media (max-width: 540px) {
  .card__delete {
    left: 100%;
  }
}

.catalog {
  margin: -10px;
  height: calc(100vh + 20px);
}

@media (max-width: 767px) {
  .catalog {
    margin: -5px 0;
    height: calc(100% + 10px);
    overflow: hidden;
  }
}

@media (max-width: 540px) {
  .catalog {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.catalog__item {
  position: relative;
  padding: 10px;
  width: 50%;
  height: 50%;
}

.catalog__item-xl {
  width: 45%;
  height: 100%;
}

@media (max-width: 1199px) {
  .catalog__item-xl {
    width: 40%;
  }
}

@media (max-width: 991px) {
  .catalog__item-xl {
    width: 33%;
  }
}

@media (max-width: 540px) {
  .catalog__item-xl {
    width: 100%;
    height: calc(50vh - 70px);
  }
}

.catalog__item-h {
  height: 100%;
}

@media (max-width: 540px) {
  .catalog__item-h {
    height: 50%;
    width: 100%;
  }
}

.catalog__block {
  display: flex;
  flex-wrap: wrap;
  width: 55%;
  height: 100%;
}

@media (max-width: 1199px) {
  .catalog__block {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .catalog__block {
    width: 67%;
  }
}

@media (max-width: 540px) {
  .catalog__block {
    width: 100%;
    height: 50vh;
  }
}

.catalog__row {
  display: flex;
  flex-wrap: wrap;
}

.catalog__row-lg {
  height: 66%;
  min-height: 200px;
}

@media (max-width: 540px) {
  .catalog__row-lg {
    height: auto;
  }
}

.catalog__row-md {
  height: 34%;
  min-height: 100px;
}

@media (max-width: 540px) {
  .catalog__row-md {
    height: auto;
  }
  .catalog__row-md .catalog__item-xl {
    height: 25vh;
  }
}

.category__item {
  width: 33.33333%;
  height: calc((100vw - 140px) / 3 * 1.45);
  max-height: calc(100vh - 176px);
}

@media (max-width: 767px) {
  .category__item {
    height: calc(100vw / 3 * 1.45);
  }
}

@media (max-width: 540px) {
  .category__item {
    height: calc(100vw / 2 * 1.45);
    width: 50%;
  }
}

.subcategory__item {
  width: 33.33333%;
  height: calc((100vw - 140px) / 3);
}

@media (max-width: 767px) {
  .subcategory__item {
    width: 33.33333%;
    height: 33.3vw;
  }
}

@media (max-width: 540px) {
  .subcategory__item {
    width: 50%;
    height: 50vw;
  }
  .subcategory__item .card__name {
    bottom: 20px;
  }
}

.products__item {
  width: 33.33333%;
  height: calc((100vw - 140px) / 3);
}

@media (max-width: 767px) {
  .products__item {
    width: 33.33333%;
    height: 33.3vw;
  }
}

@media (max-width: 540px) {
  .products__item {
    width: 50%;
    height: 50vw;
  }
}

.icon-delivery {
  font-size: 1.3em;
}

.variation {
  display: flex;
  margin: 20px 0;
  flex-wrap: wrap;
}

@media (max-width: 1199px) {
  .variation {
    flex-direction: column;
    flex-wrap: nowrap;
    padding-top: 43px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
    border: 1px solid rgba(196, 196, 196, 0.5);
  }
}

.variation-open {
  max-height: 100vh;
}

.variation__item {
  margin-bottom: 10px;
  margin-right: 20px;
}

@media (max-width: 1400px) {
  .variation__item {
    margin-right: 15px;
  }
}

@media (max-width: 1199px) {
  .variation__item {
    text-align: center;
    margin-top: 10px;
    margin-right: 0;
  }
}

.variation__item-checked {
  color: #FF7A1B;
  cursor: pointer;
  font-size: 18px;
  line-height: 46px;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  height: 43px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.variation-open .variation__item-checked {
  border-bottom: 1px solid rgba(196, 196, 196, 0.5);
}

.variation__item-checked:after {
  content: "";
  background: url(../img/svg/arrow.svg);
  width: 12px;
  height: 5px;
  position: absolute;
  top: 20px;
  right: 20px;
  transform: rotate(180deg);
  transition: all 0.5s;
}

.variation-open .variation__item-checked:after {
  transform: rotate(0deg);
}

.variation__item-checked span {
  color: #c4c4c4;
}

@media (max-width: 1199px) {
  .variation__item-checked {
    display: block;
  }
}

.variation__label {
  color: #C4C4C4;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.1s;
}

@media (max-width: 1199px) {
  .variation__label {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.variation__input {
  display: none;
}

.variation__input:checked + span {
  color: #FF7A1B;
}

@media (max-width: 1199px) {
  .variation__input:checked + span {
    color: #C4C4C4;
  }
}

.checkbox {
  margin-right: 20px;
  margin-bottom: 20px;
}

.checkbox__input {
  width: 0px;
  height: 0px;
  display: none;
}

.checkbox__input + label {
  padding-left: 28px;
}

.checkbox__input + label:before {
  content: '';
  width: 20px;
  height: 20px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border: 1px solid rgba(196, 196, 196, 0.5);
  background: #fff;
  z-index: 2;
  cursor: pointer;
}

.checkbox__input:checked + label:before {
  content: '\e900';
  color: #17B057;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  line-height: 14px;
  color: #17B057;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.quantity {
  position: relative;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
  border-radius: 40px;
  height: 21px;
  width: 80px;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}

.quantity__row {
  display: flex;
  margin-bottom: 14px;
}

.quantity__row:last-child {
  margin-bottom: 0;
}

.quantity-input {
  padding: 0;
  margin: 0;
  width: 15px;
  text-align: center;
  font-size: 15px;
}

.quantity-input:focus {
  outline: 0;
}

.quantity-button {
  font-family: Montserrat Alternates, sans-serif;
  cursor: pointer;
  padding: 0 10px;
  text-align: center;
  color: #FF7A1B;
  font-size: 22px;
  line-height: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.quantity-button:before {
  content: '';
  height: 21px;
  width: 70px;
  border-radius: 40px;
  position: absolute;
  top: 0;
  z-index: -1;
  transition: all 0.3s;
}

.quantity-down:before {
  left: 0;
}

.quantity-down:active:before {
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.07);
}

.quantity-up:before {
  right: 0;
}

.quantity-up:active:before {
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.07);
}

.product__price {
  font-size: 36px;
  margin: 20px 0;
}

@media (max-width: 767px), (max-height: 460px) {
  .product__price {
    text-align: center;
    font-size: 18px;
    color: #111111;
    font-weight: bold;
  }
}

.product__price .text {
  position: absolute;
  top: 2px;
  left: 0;
}

.characteristic__table td:first-child {
  width: 50%;
}

.table-light {
  width: 100%;
}

.table-light td {
  padding: 7px 0;
}

.hoverzoom {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}

.hoverzoom__img {
  position: relative;
  width: 100%;
}

.hoverzoom__wrap {
  position: relative;
  display: inline-flex;
}

.magnifier {
  position: absolute;
  opacity: 0;
  background: rgba(196, 196, 196, 0.4);
  border: 0.5px solid #FFFFFF;
  right: 0;
  bottom: 0;
  z-index: 5;
}

@media (max-width: 991px), (max-height: 460px) {
  .magnifier {
    display: none;
  }
}

.zoom__image {
  width: calc(100vw / 12 * 4 - 10px);
  height: calc(100vw / 12 * 4 - 10px);
  overflow: hidden;
  position: absolute;
  left: 100%;
  top: 0;
  z-index: -1;
  opacity: 0;
  transition: all 0.5s;
  display: block;
}

@media (max-width: 991px), (max-height: 460px) {
  .zoom__image {
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .zooming .zoom__image {
    opacity: 1;
    z-index: 2;
  }
}

.zoom__image::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
  height: 0px;
}

.zoom__image img {
  max-width: unset;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

@media (max-width: 991px), (max-height: 460px) {
  .zoom__image img {
    width: 200% !important;
    height: auto !important;
  }
}

.zoom__image-active {
  z-index: 99;
  opacity: 1;
}

.basket {
  height: 100vh;
  padding-right: 0px;
  display: flex;
}

@media (max-width: 767px) {
  .basket {
    flex-wrap: wrap;
    height: auto;
  }
}

.basket__left {
  width: 25vw;
  min-width: 300px;
  flex-shrink: 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1024px) {
  .basket__left {
    min-width: 250px;
  }
}

@media (max-width: 767px), (max-height: 460px) {
  .basket__left {
    width: 100%;
    min-width: unset;
    height: auto;
  }
}

.basket__sidebar {
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 40px 20px 0;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 1024px) {
  .basket__sidebar {
    padding: 20px 20px 0 10px;
  }
}

@media (max-width: 767px), (max-height: 460px) {
  .basket__sidebar {
    padding: 10px 20px 0;
  }
}

.basket__card {
  margin-bottom: 40px;
}

@media (max-width: 767px), (max-height: 460px) {
  .basket__card {
    display: flex;
  }
}

@media (max-width: 540px) {
  .basket__card {
    display: block;
  }
}

.basket__card .card {
  padding: 0;
  height: calc(100vw / 4 * 0.745);
}

@media (max-width: 767px), (max-height: 460px) {
  .basket__card .card {
    width: 33.33%;
    height: unset;
  }
}

@media (max-width: 540px) {
  .basket__card .card {
    width: 100%;
  }
}

.basket__price {
  font-size: 15px;
  position: relative;
  margin-top: 10px;
  display: inline-block;
  line-height: 20px;
}

.basket__footer {
  padding: 20px;
}

.basket__total {
  font-weight: bold;
  font-size: 18px;
  margin-left: 20px;
}

.basket__content {
  padding: 60px 40px 0;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 1024px) {
  .basket__content {
    padding: 40px;
  }
}

@media (max-width: 767px), (max-height: 460px) {
  .basket__content {
    overflow: unset;
    padding: 20px;
  }
}

@media (max-width: 540px) {
  .basket__content .btn {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.basket__answer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 5;
  margin: 0;
}

.answer__wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 18px;
  line-height: 31px;
  text-align: center;
  padding: 0 120px 0 140px;
}

@media (max-width: 767px) {
  .answer__wrap {
    padding: 0 50px;
  }
}

@media (max-width: 540px) {
  .answer__wrap {
    padding: 0 20px;
  }
}

.success p {
  color: #FF7A1B;
  width: 100%;
}

.hint {
  width: 17px;
  height: 17px;
  background: #FFFFFF;
  border: 1px solid #FF7A1B;
  border-radius: 40px;
  color: #FF7A1B;
  text-align: center;
  line-height: 15px;
  display: inline-block;
  bottom: 3px;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  order: 2;
}

@media (max-width: 767px), (max-height: 460px) {
  .hint {
    display: none;
  }
}

@media (max-width: 540px) {
  .hint {
    display: block;
  }
}

.hint:before {
  content: "";
  width: 200%;
  height: 200%;
  position: absolute;
  top: -50%;
  left: -50%;
}

.hint.hint-active + .hint__content {
  padding: 20px 0;
  max-height: 700px;
}

@media (max-width: 767px), (max-height: 460px) {
  .hint.hint-active + .hint__content {
    padding: 0;
    max-height: unset;
  }
}

@media (max-width: 540px) {
  .hint.hint-active + .hint__content {
    padding: 10px 0;
    max-height: 700px;
  }
}

.hint__content {
  width: 100%;
  padding: 0;
  max-height: 0;
  transition: all 0.5s ease-out;
  overflow: hidden;
  background: #fff;
  text-align: center;
  z-index: 4;
}

@media (max-width: 767px), (max-height: 460px) {
  .hint__content {
    max-height: unset;
  }
}

@media (max-width: 540px) {
  .hint__content {
    max-height: 0;
  }
}

.hint__title {
  color: #111111;
  margin-bottom: 20px;
  text-align: left;
  line-height: 1.3;
}

@media (max-width: 1024px) {
  .hint__title {
    margin-bottom: 10px;
  }
}

.hint__wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end;
}

@media (max-width: 767px), (max-height: 460px) {
  .hint__wrap {
    width: 65%;
    padding: 0 0 0 20px;
  }
}

@media (max-width: 540px) {
  .hint__wrap {
    padding: 0;
    width: 100%;
  }
}

.hint__wrap .basket__price {
  order: 2;
}

.count__item {
  line-height: 1.3;
  text-align: left;
  margin-bottom: 8px;
  padding-left: 40px;
  position: relative;
  color: #656565;
}

.count__item:last-of-type {
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .count__item {
    padding-left: 28px;
  }
}

.count-decor {
  width: 31px;
  height: 19px;
  background: #FFFFFF;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
  border-radius: 40px;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
  line-height: 19px;
  vertical-align: text-bottom;
  position: absolute;
  left: 0;
  top: 2px;
}

@media (max-width: 1024px) {
  .count-decor {
    width: 21px;
  }
}

.form__row {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .form__row {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .form__row {
    flex-wrap: nowrap;
  }
}

@media (max-width: 540px) {
  .form__row {
    flex-wrap: wrap;
  }
}

.form__desc {
  font-size: 11px;
  color: #656565;
}

@media (max-width: 991px) {
  .form__desc {
    padding: 5px 20px 0;
    line-height: 1.2;
    max-width: 325px;
  }
}

#basket__form {
  transform: translateZ(0px) !important;
}

.input {
  z-index: 1;
  transform: translateZ(0px) !important;
  -webkit-user-select: text;
  -webkit-text-fill-color: #111111;
  -webkit-opacity: 1;
  color: #111111;
  font-family: inherit;
  width: 305px;
  max-width: 100%;
  height: 32px;
  border: 1px solid rgba(196, 196, 196, 0.5);
  background: transparent;
  line-height: 32px;
  margin-right: 20px;
  padding: 0 20px;
  position: relative;
  display: inline-block;
  transition: all 0.3s;
  padding-top: 5px;
}

@media (max-width: 540px) {
  .input {
    width: 100%;
  }
}

.input__wrap {
  position: relative;
}

@media (max-width: 540px) {
  .input__wrap {
    width: 100%;
  }
}

.input__placeholder {
  color: #C4C4C4;
  position: absolute;
  white-space: nowrap;
  top: 5px;
  left: 10px;
  transition: all 0.3s;
  padding: 0 10px;
  background: #fff;
  z-index: -1;
}

.input:focus, .input:active {
  border-color: #FF7A1B;
}

.input:focus + .input__placeholder, .input:active + .input__placeholder {
  color: #FF7A1B;
  top: -6px;
  font-size: 11px;
  z-index: 1;
}

.input.input-focus + .input__placeholder {
  top: -6px;
  font-size: 11px;
  z-index: 1;
}

.input.input-error {
  border-color: #ff1c1c;
}

textarea.input {
  height: auto;
  width: 100%;
  margin: 0;
}

textarea:focus {
  outline: none;
}

.select {
  width: 305px;
  height: 32px;
  border: 1px solid rgba(196, 196, 196, 0.5);
  line-height: 32px;
  margin-right: 20px;
  position: relative;
  display: inline-block;
}

@media (max-width: 540px) {
  .select {
    width: 100%;
  }
}

.select-wrapper {
  position: relative;
  display: inline-block;
  user-select: none;
}

@media (max-width: 540px) {
  .select-wrapper {
    width: 100%;
  }
}

.select-wrapper select {
  display: none;
}

.select-trigger {
  position: relative;
  display: block;
  background: #fff;
  padding: 0 20px;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;
  color: #656565;
  overflow: hidden;
}

.select-trigger:after {
  position: absolute;
  display: block;
  content: '';
  width: 12px;
  height: 5px;
  top: 45%;
  right: 15px;
  transform: rotate(180deg);
  background-image: url("../img/svg/arrow.svg");
  background-size: contain;
  transition: all 0.5s;
}

.select.opened .select-trigger:after {
  transform: rotate(0deg);
}

.select.opened .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translateY(0);
}

.custom-options {
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  min-width: 100%;
  margin: 0;
  border: 1px solid rgba(196, 196, 196, 0.5);
  box-sizing: border-box;
  background: #fff;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(-15px);
  z-index: 9;
  overflow: hidden;
  padding: 15px 0 5px;
}

.option-hover:before {
  background: #fff;
}

.custom-option {
  position: relative;
  display: block;
  padding: 0 20px;
  line-height: 20px;
  min-height: 20px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.1s;
}

.custom-option:last-of-type {
  border-bottom: 0;
}

.custom-option:hover,
.custom-option.selection {
  color: #FF7A1B;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 0;
}

.custom-option.selection {
  display: none;
}

.review__form {
  margin: 0 0 60px;
}

.review__input {
  width: 100%;
}

.review__block {
  padding: 20px;
  margin: 0 0 20px;
  border: 1px solid rgba(196, 196, 196, 0.5);
}

.review__block .rating__star {
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
}

.review__block .rating {
  margin-right: 0;
}

.review__subcomment {
  margin-left: 80px;
  margin-top: -10px;
}

@media (max-width: 767px), (max-height: 460px) {
  .review__subcomment {
    margin-left: 65px;
  }
}

@media (max-width: 475px) {
  .review__subcomment {
    margin-left: 40px;
  }
}

.review__name {
  font-size: 15px;
  color: #111111;
  display: inline-block;
  margin-right: auto;
}

.review__answer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 5;
}

.rating {
  width: auto;
  margin: 0;
  position: relative;
  display: inline-flex;
  flex-direction: row-reverse;
  vertical-align: middle;
}

@media (max-width: 767px), (max-height: 460px) {
  .rating {
    margin-right: 40px;
  }
}

.rating__star {
  display: inline-block;
  font-size: 24px;
  color: #FBB202;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
}

.rating__star:after {
  content: '\e903';
}

.rating__star-check ~ .rating__star:after, .rating__star-check:after {
  content: '\e902';
}

.rating__input {
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 5;
}

.rating__input:checked ~ .rating__star:after,
.rating__input:hover ~ .rating__star:after {
  content: '\e902';
}

.rating__input:nth-of-type(1) {
  right: 0px;
}

.rating__input:nth-of-type(2) {
  right: 24px;
}

.rating__input:nth-of-type(3) {
  right: 48px;
}

.rating__input:nth-of-type(4) {
  right: 72px;
}

.rating__input:nth-of-type(5) {
  right: 96px;
}

.rating__input:nth-of-type(6) {
  right: 120px;
}

#map {
  width: 100%;
  height: 100%;
}

.gmnoprint,
.gmnoprint {
  display: none;
}

@media (max-width: 767px), (max-height: 460px) {
  .blog__row {
    margin: 0;
    padding: 0 10px;
  }
}

.blog__item {
  display: block;
  width: 33.33%;
  padding: 0 20px;
  margin-bottom: 20px;
}

.blog__item:hover .blog__img {
  transform: scale(1.05);
}

@media (max-width: 767px), (max-height: 460px) {
  .blog__item {
    padding: 0 10px;
  }
}

@media (max-width: 567px), (max-height: 460px) {
  .blog__item {
    width: 100%;
    text-align: center;
    max-width: 350px;
    margin: 0 auto;
  }
}

.blog__image {
  width: 100%;
  padding-top: 157%;
  position: relative;
  margin: 0 auto 20px;
}

@media (max-width: 567px), (max-height: 460px) {
  .blog__image {
    padding-top: 0;
  }
}

.blog__img {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 567px), (max-height: 460px) {
  .blog__img {
    position: unset;
    height: auto;
  }
}

.post {
  max-width: 880px;
  padding: 0 40px 40px;
}

@media (max-height: 460px), (max-width: 767px) {
  .post {
    padding: 0 20px 20px;
  }
}

@media (max-width: 540px) {
  .post {
    padding: 0 20px 10px;
  }
}

.pagination {
  margin: 40px auto 30px;
  text-align: center;
}

.pagination__arrow {
  background: transparent;
  border: none;
  cursor: pointer;
  background: url(../img/svg/arrow.svg);
  width: 15px;
  height: 6px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 5px;
}

.pagination__prev {
  transform: rotate(-90deg);
}

.pagination__next {
  transform: rotate(90deg);
}

.pagination__list {
  display: inline-flex;
  margin: 0;
  list-style: none;
}

.pagination__more {
  cursor: pointer;
  margin: 0 10px;
}

.pagination__link {
  color: #FF7A1B;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
}

.pagination__link-active {
  background: #FCFCFC;
}

.pagination__link:hover {
  text-decoration: none;
  color: #FF7A1B;
}
