@import "common-components/normalize";
//@import "common-components/fonts";
@import "common-components/animations";
@import "lib-components/css-hacks";
@import "../fonts/iconmoon/style.css";
//------variables
$main-color: #FF7A1B;
$text-color: #111111;
$secondary-text-color: #656565;
$sidebar-width: 140px;
body {
    //font-family: 'montserrat', sans-serif;
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 14px;
    line-height: 1.7;
    color: $text-color;
}
.basket_update_info{
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    color: #FF7A1B;
    z-index: 100500;
    padding: 20px 25px;
    font-weight: bold;
    font-size: 18px;
    top: -90px;
    transition: .4s;
    border: 1px solid #FF7A1B;
    white-space: nowrap;
    text-align: center;
}
.basket_update_info.active{
    top: 50px;
}
h1,
.h1 {
    //font-family: 'montserrat', sans-serif;
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: bold;
    color: $text-color;
    font-size: 36px;
    line-height: 1.7;
    margin: 15px 0 15px;
    @media(max-width: 991px) {
        font-size: 30px;
    }
    @media(max-width: 767px),
    (max-height: 460px) {
        font-size: 24px;
    }
    @media(max-width: 540px) {
        font-size: 18px;
    }
}
h2,
.h2 {
    //font-family: 'montserrat', sans-serif;
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: bold;
    color: $text-color;
    font-size: 24px;
    line-height: 1.4;
    @media(max-width: 767px), (max-height: 460px) {
        font-size: 18px;
    }
    @media(max-width: 540px) {
        font-size: 16px;
    }
}
h3,
.h3 {
    //font-family: 'montserrat', sans-serif;
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: bold;
    color: $text-color;
    font-size: 18px;
    line-height: 1.4;
    white-space: normal;
    @media(max-width: 767px), (max-height: 460px) {
        font-size: 16px;
    }
    @media(max-width: 540px) {
        font-size: 14px;
    }
}
p {
    font-family: 'Montserrat Alternates', sans-serif;
    line-height: 1.7;
    margin: 20px 0;
}
.hidden{
    display: none;
}
label {
    cursor: pointer;
}
.max-767 {
    display: none;
    @media(max-width: 767px), (max-height: 460px) {
        display: block;
    }
}
.max-991 {
    display: none;
    @media(max-width: 991px), (max-height: 460px) {
        display: block;
    }
}
.iconmoon {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.nowrap {
    white-space: nowrap;
}
.overflow-hidden {
    overflow: hidden;
}
.for-mobile {
    display: none;
    @media (max-width: 767px), (max-height: 460px) {
        display: block;
    }
}
.scroll {
    overflow: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch;
}
.img {
    &__full {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    &__center {
        object-position: center;
    }
    &__top {
        object-position: top;
    }
    &__contain {
        object-fit: contain;
        max-height: 100%;
    }
}
.btn {
    height: 36px;
    width: 185px;
    line-height: 36px;
    border-radius: 18px;
    font-family: montserrat, sans-serif;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    &__full {
        background: $main-color;
        position: relative;
        color: #fff;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
        transition: all 0.3s;
        z-index: 1;
        // &:before{
        // 	content: '';
        // 	width: 100%;
        // 	height: 100%;
        // 	border-radius: inherit;
        // 	z-index: -1;
        // 	opacity: 0;
        // 	position: absolute;
        // 	top: 0;
        // 	left: 0;
        // 	background: linear-gradient(180deg, #FF6D04 50%, #fff 166.34%);
        // 	transition: all 0.3s;
        // }
        &:hover {
            // background: #FF6D04;
            // box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.07)
            // &:before{
            // 	opacity: 1;
            // }
        }
        &:active {
            background: #FF6D04;
            box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.07);
            // &:before{
            // 	opacity: 0;
            // }
        }
        &:focus {
            background: #FF6D04;
            box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.07);
            // &:before{
            // 	opacity: 0;
            // }
        }
    }
    &__icon {
        height: 52px;
        width: 52px;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__icon-full {
        background: $main-color;
        position: relative;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
        color: #fff;
        transition: all 0.3s;
        z-index: 1;
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            border-radius: inherit;
            z-index: -1;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, $main-color 50%, #fff 166.34%);
            transition: all 0.3s;
        }
        &:hover {
            background: $main-color;
            box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.07);
            &:before {
                opacity: 1;
            }
        }
        &:active {
            background: #FF6D04;
            box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.07);
            &:before {
                opacity: 0;
            }
        }
        &:focus {
            background: #FF6D04;
            box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.07);
            &:before {
                opacity: 0;
            }
        }
    }
    &__icon-empty {
        background-color: #fff;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
        color: $main-color;
    }
    &__menu {
        width: 27px;
        cursor: pointer;
        z-index: 9;
        margin-left: 10px;
        &:hover {
            .line {
                &:after {
                    width: 50%;
                }
                &:last-child {
                    transform: translateX(90%);
                    &:after {
                        width: 65%;
                    }
                }
            }
        }
        @media (pointer:coarse) {
            &:hover {
                .line {
                    &:after {
                        width: 0;
                    }
                    &:last-child {
                        transform: translateX(0%);
                        &:after {
                            width: 0;
                        }
                    }
                }
            }
        }
        &.btn__menu-open {
            .line {
                &:after {
                    width: 50%;
                }
                &:last-child {
                    transform: translateX(90%);
                    &:after {
                        width: 65%;
                    }
                }
            }
        }
        @media(max-width: 767px),
        (max-height: 460px) {
            position: fixed;
            top: 25px;
            right: 20px;
        }
        .line {
            width: 100%;
            height: 2px;
            background-color: $main-color;
            transition: 0.25s;
            margin-bottom: 7px;
            &:last-child {
                width: 52%;
            }
            &:after {
                content: '';
                background: #fff;
                transition: .5s;
                opacity: .8;
                width: 0;
                height: 2px;
                display: block;
            }
        }
    }
}
.link {
    color: $main-color;
    transition: all 0.3s;
    &:hover {
        transform: scale(1.1);
    }
}
.text {
    color: $text-color;
    font-size: 14px;
}
.text-notify {
    color: #17B057;
}
.text-secondary {
    color: $secondary-text-color;
    font-size: 11px;
    font-weight: 200;
    line-height: 20px;
}
.text-light {
    color: $secondary-text-color;
    font-weight: 200;
}
.text-lg {
    font-size: 18px;
    display: inline-block;
}
.list {
    margin-bottom: 20px;
    &__item {
        margin-bottom: 20px;
        padding-left: 15px;
        &:before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $main-color;
            position: absolute;
            top: 7px;
            left: 0;
        }
    }
}
.hr-line {
    margin: 20px -10px;
    width: 100%;
    height: 1px;
    background-color: #D7DEED;
    &-s {
        width: 92px;
        margin-bottom: 20px;
        height: 1px;
        background-color: #D7DEED;
    }
}
.tabs {
    margin: 0 -10px;
    display: flex;
    @media(max-width: 767px), (max-height: 460px) {
        flex-wrap: wrap;
    }
    &__content {
        margin: 0 -10px;
        @media(max-width: 767px), (max-height: 460px) {
            margin: 0 -20px;
        }
    }
}
.tab {
    width: 100%;
    cursor: pointer;
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid rgba(196, 196, 196, 0.2);
    cursor: pointer;
    color: #C4C4C4;
    transition: all 0.5s;
    @media (max-width: 767px) {
        display: none;
    }
    &-active {
        color: $main-color;
        border-color: $main-color;
    }
    &-mobil {
        display: none;
        @media (max-width: 767px) {
            display: block;
        }
        &.tab-active {
            color: inherit;
            border-color: #656565;
        }
    }
    &__content {
        padding: 40px 10px;
        overflow: hidden;
        transition: opacity 0.5s, width 0 0.5s;
        @media (max-width: 767px) {
            display: none;
            padding: 40px 20px;
        }
    }
    &__icon {
        font-size: 22px;
        margin-bottom: 15px;
        @media (max-width: 767px), (max-height: 460px) {
            margin-bottom: 0;
        }
    }
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    padding: 20px 10px;
    width: $sidebar-width;
    @media(max-width: 767px), (max-height: 460px) {
        width: 100%;
        height: 70px;
        background: #fff;
        padding: 11px 20px 11px;
        display: flex;
        align-items: center;
    }
    &__logo {
        margin-bottom: 40px;
        width: auto;
        z-index: 5;
        display: inline-block;
        @media(max-width: 767px), (max-height: 460px) {
            margin-bottom: 0;
        }
    }
}
.logo__img {
    display: block;
}
.menu {
    // width: 50%;
    // min-width: 450px;
    width: 0;
    min-width: 0;
    overflow: hidden;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.8s;
    z-index: 8;
    @media(max-width: 767px), (max-height: 460px) {
        width: 100%;
        height: 0;
    }
    &.menu__open {
        width: 50%;
        min-width: 450px;
        transition: all 0.8s;
        @media(max-width: 767px), (max-height: 460px) {
            width: 100%;
            min-width: unset;
            height: 100%;
            background-color: #fff;
        }
        .menu__list {
            opacity: 1;
            transition: opacity 0.5s;
        }
        .menu__overlay {
            width: 100%;
            opacity: 1;
            transition: opacity 0.5s 0.3s;
        }
    }
    &__overlay {
        width: 0;
        opacity: 0;
        height: 100%;
        position: fixed;
        z-index: 0;
        background-color: rgba(116, 88, 68, 0.61);
        transition: opacity 0.5s, width 0s 0.5s;
        @media(max-width: 767px), (max-height: 460px) {
            display: none;
        }
    }
    &__wrap {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: #FCFCFC;
        z-index: 2;
        @media(max-width: 767px), (max-height: 460px) {
            overflow: auto;
            padding: 0 20px 40px;
            margin-top: 70px;
            height: calc(100% - 70px);
            -webkit-overflow-scrolling: touch;
        }
    }
    &__list {
        margin-left: 190px; //130+60
        margin-top: 60px;
        color: $text-color;
        transition: all 0.1s;
        opacity: 0;
        transition: opacity 0.5s 0.5s;
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently*/
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        & ::selection {
            background-color: transparent;
        }
        &::-moz-selection {
            background-color: transparent;
        }
        &:hover {
            @media(min-width: 768px) and (min-height: 461px) {
                color: #dcddde;
            }
        }
        @media(max-width: 991px) {
            margin-left: 170px;
        }
        @media (max-height: 700px) {
            margin-top: 20px;
        }
        @media(max-width: 767px),
        (max-height: 460px) {
            margin-left: 0;
            margin-top: 0;
            color: $secondary-text-color;
        }
        // &-open{
        // 	@media(max-width: 767px), (max-height: 460px){
        // 		.menu__item{
        // 			max-height: 0;
        // 			padding: 0;
        // 			overflow: hidden;
        // 		}
        // 	}
        // }
    }
    &__sublist {
        position: fixed;
        top: 70px;
        left: 50%;
        width: 33%;
        padding-left: 60px;
        color: $text-color;
        opacity: 0;
        top: -100%;
        transition: color 0.1s, opacity 0.5s, top 0s 0.5s;
        z-index: -1;
        @media(max-width: 900px) {
            left: 450px;
            padding-left: 20px;
        }
        @media(max-width: 900px) {
            left: 450px;
            padding-left: 20px;
        }
        &:hover {
            @media(min-width: 768px) and (min-height: 461px) {
                z-index: 3;
                transition: color 0.1s, opacity 0.5s 0.5s, top 0s 0s;
                &+.menu__item-lg {
                    color: $text-color;
                    padding-left: 10px;
                }
            }
        }
        &:before {
            content: "";
            width: 33%;
            height: 100%;
            background: #fff;
            opacity: 0;
            display: block;
            position: fixed;
            top: -100%;
            left: 50%;
            transition: opacity 0.5s, top 0s 0.5s;
            @media(max-width: 900px) {
                left: 450px;
            }
            @media(max-width: 767px),
            (max-height: 460px) {
                display: none;
            }
        }
    }
    &__back {
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: opacity 0.5s, height 0s 0.5s;
        color: $main-color;
        display: flex;
        align-items: center;
    }
    &__parent {
        transition: height 0.5s;
        &:hover,
        &:focus {
            @media(min-width: 768px) and (min-height: 461px) {
                .menu__sublist {
                    top: 70px;
                    opacity: 1;
                    z-index: 3;
                    transition: color 0.1s, opacity 0.5s, top 0s 0s;
                    &:before {
                        top: 0;
                        opacity: 0.85;
                        transition: color 0.1s, opacity 0.5s, top 0s 0s;
                    }
                }
            }
            @media (max-height: 700px) {
                .menu__sublist {
                    top: 20px;
                }
            }
            @media(max-width: 767px) {
                color: $text-color;
            }
        }
    }
    &__item {
        transition: all 0.3s;
        display: block;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        &-lg {
            font-size: 18px;
            font-weight: bold;
            padding: 30px 0;
            @media (max-height: 700px) {
                padding: 20px 0;
            }
            @media (max-height: 560px) {
                padding: 10px 0;
            }
            @media(max-width: 767px),
            (max-height: 460px) {
                padding: 15px 0;
            }
            &:hover {
                @media(min-width: 767px) and (min-height: 461px) {
                    color: $text-color;
                    padding-left: 10px;
                }
            }
        }
        &-md {
            padding: 15px 0;
            color: $secondary-text-color;
            display: block;
            &:hover {
                color: $text-color;
            }
        }
    }
    &__search {
        display: none;
        @media(max-width: 767px), (max-height: 460px) {
            display: block;
            margin-bottom: 25px;
        }
    }
    &__seraching {
        position: absolute;
        top: 10px;
        left: 1px;
        z-index: 2;
    }
    &__input {
        border-bottom: 1px solid rgba(196, 196, 196, 0.5);
        height: 35px;
        width: 100%;
        padding-left: 27px;
    }
}
@media(max-width: 767px),
(max-height: 460px) {
    .basket_update_info{
        padding: 15px 45px;
        font-size: 16px;
    }
    .basket_update_info.active{
        top: 90px;
    }
    .menu {
        &__parent-open {
            // .menu__back{
            // 	opacity: 1;
            // 	height: 40px;
            // 	transition: opacity 0.5s;
            // 	cursor: pointer;
            // }
            .menu__sublist {
                max-height: 500px;
                margin-top: 20px;
                z-index: 99;
                transition: all 0.8s;
            }
            // >.menu__item{
            // 	color: #c4c4c4;
            // 	font-size: 14px;
            // }
        }
        &__list-open {
            .menu__sublist {
                transition: all 0.8s;
            }
            .menu__parent-open {
                .menu__sublist {
                    transition: all 0.8s 0.5s;
                }
            }
        }
        &__sublist {
            position: relative;
            padding: 0;
            width: 100%;
            top: unset !important;
            height: auto;
            overflow: hidden;
            left: unset;
            opacity: 1;
            color: $secondary-text-color;
            padding-left: 40px;
            max-height: 0;
            transition: all 0.8s;
        }
        &__list+.menu__list {
            margin-top: 40px;
        }
    }
    a.menu__item:hover {
        color: $main-color;
    }
}
.lang {
    display: flex;
    margin-left: 190px; //130+60
    margin-top: auto;
    margin-bottom: 20px;
    @media(max-width: 767px), (max-height: 460px) {
        margin-left: 0;
    }
    &__item {
        margin-right: 20px;
    }
    &__link {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        font-weight: 200;
        color: #C4C4C4;
        display: block;
        @media(max-width: 767px), (max-height: 460px) {
            text-align: left;
        }
        &:hover {
            color: $main-color;
        }
    }
    &__active {
        color: $main-color;
    }
}
#btnSearch {
    position: fixed;
    top: 20px;
    right: 20px;
    @media (max-width: 1199px) {
        right: 10px;
    }
}
#formSearch {
    position: fixed;
    top: 20px;
    right: 20px;
    @media (max-width: 1199px) {
        right: 10px;
    }
    @media(max-width: 767px),
    (max-height: 460px) {
        display: none;
    }
}
.search {
    &__form {
        width: 50px;
        max-width: calc(100% - 20px);
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
        overflow: hidden;
        border-radius: 25px;
        transition: all 0.5s;
        &-open {
            width: 363px;
        }
    }
    &__input {
        height: 50px;
        width: 100%;
        padding: 0 50px 0 40px;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
        border-radius: 25px;
    }
}
#btnBasket {
    position: fixed;
    top: 90px;
    right: 20px;
    z-index: 6;
    @media (max-width: 1199px) {
        right: 10px;
    }
    @media(max-width: 767px),
    (max-height: 460px) {
        top: 9px;
        right: 65px;
    }
}
.btnBasket__count {
    background-color: #FF0000;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: #fff;
    font-size: 11px;
    text-align: center;
    line-height: 16px;
    bottom: 9px;
    right: 9px;
    position: absolute;
}
#btnCatalog {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9;
    @media (max-width: 1199px) {
        right: 10px;
    }
    @media(max-width: 991px),
    (max-height: 460px) {
        display: none;
    }
}
.btn-ctalog__img {
    width: 16px;
}
.sidebar {
    width: $sidebar-width;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    transition: all 0.5s 0s;
    @media(max-width: 767px), (max-height: 460px) {
        position: relative;
        top: unset;
        left: unset;
        width: 100%;
        height: auto;
        padding: 10px 20px;
    }
    &__block {
        padding: 10px 0;
        margin-top: 30px;
        @media(max-width: 767px), (max-height: 460px) {
            margin-top: 10px;
        }
        &:before {
            content: '';
            display: block;
            width: 32px;
            height: 1px;
            background-color: $main-color;
            position: absolute;
            top: 0;
            left: -20px;
            opacity: 0.4;
            @media(max-width: 767px), (max-height: 460px) {
                display: none;
            }
        }
    }
    &__show {
        z-index: 8;
        opacity: 1;
        height: 100%;
        transition: all 0.5s 0.5s;
        @media(max-width: 767px), (max-height: 460px) {
            z-index: 1;
        }
    }
    &__link {
        margin-bottom: 10px;
        display: inline-block;
        transition: all 0.1s;
        color: #111;
        @media(max-width: 767px), (max-height: 460px) {
            margin-right: 40px;
        }
        @media(max-width: 425px) {
            margin-right: 15px;
        }
        &:hover {
            transform: scale(1.05, 1.05);
            color: $main-color;
        }
    }
}
.phone__text {
    color: #C4C4C4;
    font-size: 11px;
    margin-bottom: 20px;
}
.main {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 7;
    @media(max-width: 767px), (max-height: 460px) {
        flex-direction: column-reverse;
    }
    &__slider {
        width: 50%;
        @media(max-width: 767px), (max-height: 460px) {
            width: 100%;
            &#mainSlider {
                height: 250px;
            }
            &#mainImgSlider {
                height: calc(100% - 250px);
            }
        }
        @media(max-width: 540px),
        (max-height: 460px) {
            &#mainSlider {
                height: 180px;
            }
            &#mainImgSlider {
                height: calc(100% - 180px);
            }
        }
    }
    &__item {
        display: flex;
        width: 100%;
        height: 100%;
    }
    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        @media(max-width: 767px), (max-height: 460px) {
            padding-bottom: 50px;
        }
    }
    &__title {
        text-align: center;
        width: 600px;
        max-width: 95%;
        margin-bottom: 30px;
        animation-name: sliderContentHide;
        animation-duration: .4s;
        animation-fill-mode: both;
        opacity: 0;
        line-height: 1.7;
        @media(max-width: 767px) {
            margin-top: auto;
            margin-bottom: 0;
        }
    }
    &__text {
        width: 450px;
        max-width: 95%;
        text-align: center;
        margin-bottom: 60px;
        animation-name: sliderContentHide;
        animation-duration: .4s;
        animation-fill-mode: both;
        opacity: 0;
        @media(max-width: 767px) {
            margin-top: 0;
            margin-bottom: 15px;
        }
        @media(max-width: 540px),
        (max-height: 460px) {
            display: none;
        }
    }
    &__img {
        width: 100%;
        height: 100%;
    }
    &__link {
        @media(max-width: 767px), (max-height: 460px) {
            margin-top: auto;
        }
    }
    .slick-vertical .slick-slide {
        border: none;
    }
    .slick-list,
    .slick-track,
    .slick-vertical .slick-slide {
        height: 100% !important;
    }
    .slick-dots {
        position: absolute;
        bottom: 15px;
        left: 0;
        width: 100%;
        text-align: center;
        li {
            display: inline-flex;
            cursor: pointer;
            width: 20px;
            height: 20px;
            justify-content: center;
            align-items: center;
            vertical-align: bottom;
            button {
                color: transparent;
                width: 5px;
                height: 5px;
                cursor: pointer;
                border-radius: 50px;
                background-color: $main-color;
                transition: all 0.5s;
            }
            &.slick-active button {
                width: 10px;
                height: 10px;
            }
        }
    }
}
.slick-active .main__text,
.slick-active .main__title {
    animation-name: sliderContentShow;
    animation-delay: .2s;
}
.slider__item {
    height: 100%;
}
.slick-list,
.slick-track,
.slick-slide,
.slick-slide>div {
    height: 100%;
}
.slick-vertical .slick-slide {
    border: none;
}
.slick-track,
.slick-list {
    -webkit-perspective: 2000;
    -webkit-backface-visibility: hidden;
}
.fancybox-container {
    z-index: 99;
}
.fancybox-navigation {
    height: 100%;
}
.content {
    margin-left: $sidebar-width;
    width: calc(100% - 140px);
    padding-right: 20px;
    //line-height: 1.75;
    color: #656565;
    @media(max-width: 767px), (max-height: 460px) {
        margin-left: 0;
        padding-right: 0;
        width: 100%;
        margin-top: 70px;
        height: auto;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
    &__header {
        padding: 20px 40px 40px;
        @media(max-width: 767px), (max-height: 460px) {
            padding: 20px 20px 15px;
        }
        // @media(max-width: 540px){
        //   		padding: 10px;
        //   	}
    }
}
.vertical {
    overflow: hidden;
    height: 100vh;
    display: flex;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    @media (max-width: 767px), (max-height: 460px) {
        flex-wrap: wrap;
        overflow: auto;
        height: auto;
    }
    &__wrap {
        width: 100%;
        padding: 0 20px;
        overflow: auto;
        flex-shrink: 2;
        @media (max-width: 991px) {
            padding-left: 0;
        }
        @media (max-width: 767px),
        (max-height: 460px) {
            padding-left: 20px;
        }
    }
    &__bg {
        width: auto;
        height: 100%;
        flex-shrink: 1;
        display: flex;
        @media (max-width: 767px), (max-height: 460px) {
            height: auto;
            width: 100%;
            flex-wrap: wrap;
        }
        .slick-dots {
            padding-right: 20px;
            list-style: none;
            margin: 0;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            @media(max-width: 991px) {
                position: absolute;
                bottom: 0px;
                left: 0;
                width: 100%;
                text-align: center;
                z-index: 7;
            }
            @media(max-width: 767px),
            (max-height: 460px) {
                position: relative;
                margin-top: 13px;
                bottom: unset;
                left: unset;
            }
            li {
                width: 7.5vw;
                height: 10vw;
                margin-bottom: 20px;
                cursor: pointer;
                cursor: pointer;
                @media(max-width: 991px) {
                    width: auto;
                    height: auto;
                    padding: 7px;
                    margin: 0px;
                    display: inline-block;
                    transition: all 0.3s;
                    &.slick-active {
                        margin: -3px;
                    }
                }
            }
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    &__bg-img {
        width: 45%;
        width: calc(100vw / 12 * 5 - 10px);
        padding-right: 20px;
        flex-shrink: 1;
        height: 100%;
        //overflow: hidden;
        display: flex;
        @media(max-width: 1024px) {
            width: calc(100vw / 12 * 4 - 10px);
        }
        @media(max-width: 767px),
        (max-height: 460px) {
            width: 100%;
            height: calc(100vh - 190px);
            min-height: 100vw;
            padding-right: 0;
        }
    }
    &__content {
        padding: 20px 60px 40px 20px;
        @media(max-width: 1199px) {
            padding-right: 40px;
            padding-left: 0; //for product page
        }
        @media(max-width: 767px),
        (max-height: 460px) {
            padding: 10px 0 20px;
        }
    }
    &__gallery {
        width: 9%;
        width: calc(100vw / 12 - 20px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        flex-shrink: 0;
    }
    &__item {
        margin-bottom: 20px;
        cursor: pointer;
        width: 100%;
        height: calc((100vw / 12 - 20px) * 1.3);
    }
    &__slider {
        height: 100%;
    }
}
.btn__zoom {
    display: none;
    @media(max-width: 991px) {
        display: flex;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 7;
    }
    @media(max-width: 767px) {
        top: unset;
        bottom: 20px;
    }
    .icon-del {
        transform: rotate(45deg);
        transition: all 0.5s;
        display: block;
        .zooming & {
            transform: rotate(180deg);
        }
    }
    &-active {
        .icon-del {
            transform: rotate(225deg);
        }
    }
}
.hoverzoom,
.vertical__slider,
.bg-img__item,
.slick-vertical .slick-list,
.slick-vertical .slick-track,
.slick-vertical .slick-slide,
.slick-vertical .slick-slide>div,
.bg-img__item {
    width: 100%;
}
.slick-vertical .slick-list,
.slick-vertical .slick-track,
.slick-vertical .slick-slide {
    height: 100% !important;
}
.slick-dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $main-color;
    transition: all 0.3s;
}
.slick-active .slick-dot {
    width: 10px;
    height: 10px;
}
.vertical__slid {
    height: 100%;
}
.bg-img__item {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: cenetr;
    @media(max-width: 767px), (max-height: 460px) {
        max-width: 100%;
        height: 100%;
        max-height: 100%;
    }
    &.bg-img__item-active {
        z-index: 3;
        -webkit-animation: slide 1s 1;
    }
    &:not(.bg-img__item-active) {
        -webkit-animation: hidden 1s 1;
    }
}
.breadcrumb {
    font-size: 13px;
    color: #B5B5B5;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    @media(max-width: 540px) {
        display: none;
    }
}
.card {
    position: relative;
    padding: 10px;
    // @media(max-width: 767px){
    // 	padding: 5px;
    // }
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 10px;
        @media(max-width: 767px) {
            margin: 0 10px;
            margin-bottom: 0;
        }
    }
    &__name {
        position: absolute;
        display: inline-block;
        bottom: 40px;
        left: 0;
        padding: 0 10px;
        line-height: 35px;
        height: 35px;
        background-color: rgba(255, 255, 255, 0.65);
        font-size: 15px;
        @media(max-width: 767px) {
            font-size: 14px;
            padding: 0 5px;
        }
    }
    &__price {
        position: absolute;
        display: inline-block;
        bottom: 5px;
        left: 0;
        padding: 0 10px;
        line-height: 35px;
        height: 35px;
        background-color: rgba(255, 255, 255, 0.65);
        font-size: 15px;
        @media(max-width: 767px) {
            font-size: 14px;
        }
        &-old {
            text-decoration: line-through;
            margin-right: 12px;
            font-size: 12px;
        }
        &-new {
            color: #FF0000;
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: all 0.5s;
    }
    &__link {
        display: block;
        height: 100%;
        overflow: hidden;
        &:hover {
            .card__img {
                width: calc(100% + 10px);
                height: calc(100% + 10px);
            }
        }
    }
    &__delete {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 20px;
        height: 20px;
        background: #FFFFFF;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
        border-radius: 40px;
        z-index: 2;
        color: $main-color;
        text-align: center;
        line-height: 22px;
        font-size: 9px;
        cursor: pointer;
        @media(max-width: 767px), (max-height: 460px) {
            right: unset;
            left: 33.33%;
            margin-left: -15px;
        }
        @media(max-width: 540px) {
            left: 100%;
        }
    }
}
.catalog {
    margin: -10px;
    height: calc(100vh + 20px);
    @media(max-width: 767px) {
        margin: -5px 0;
        height: calc(100% + 10px);
        overflow: hidden;
    }
    @media(max-width: 540px) {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
    &__item {
        position: relative;
        padding: 10px;
        width: 50%;
        height: 50%;
        // @media(max-width: 767px){
        // 	padding: 5px;
        // }
        &-xl {
            width: 45%;
            height: 100%;
            @media(max-width: 1199px) {
                width: 40%;
            }
            @media(max-width: 991px) {
                width: 33%;
            }
            @media(max-width: 540px) {
                width: 100%;
                height: calc(50vh - 70px);
            }
        }
        &-h {
            height: 100%;
            @media(max-width: 540px) {
                height: 50%;
                width: 100%;
            }
        }
    }
    &__block {
        display: flex;
        flex-wrap: wrap;
        width: 55%;
        height: 100%;
        @media(max-width: 1199px) {
            width: 60%;
        }
        @media(max-width: 991px) {
            width: 67%;
        }
        @media(max-width: 540px) {
            width: 100%;
            height: 50vh;
        }
    }
    &__row {
        display: flex;
        flex-wrap: wrap;
        // @media(max-width: 767px){
        // 	margin-left: -5px;
        //     margin-right: -5px;
        // }
        &-lg {
            height: 66%;
            min-height: 200px;
            @media(max-width: 540px) {
                height: auto;
            }
        }
        &-md {
            height: 34%;
            min-height: 100px;
            @media(max-width: 540px) {
                height: auto;
                .catalog__item-xl {
                    height: 25vh;
                }
            }
        }
    }
}
.category {
    &__item {
        width: 33.33333%;
        height: calc((100vw - 140px) / 3 * 1.45);
        max-height: calc(100vh - 176px);
        @media(max-width: 767px) {
            height: calc(100vw / 3 * 1.45);
        }
        @media(max-width: 540px) {
            height: calc(100vw / 2 * 1.45);
            width: 50%;
        }
    }
}
.subcategory__item {
    width: 33.33333%;
    height: calc((100vw - 140px) / 3);
    @media(max-width: 767px) {
        width: 33.33333%;
        height: 33.3vw;
    }
    @media(max-width: 540px) {
        width: 50%;
        height: 50vw;
        .card__name {
            bottom: 20px;
        }
    }
}
.products__item {
    width: 33.33333%;
    height: calc((100vw - 140px) / 3);
    @media(max-width: 767px) {
        width: 33.33333%;
        height: 33.3vw;
    }
    @media(max-width: 540px) {
        width: 50%;
        height: 50vw;
    }
}
.icon-delivery {
    font-size: 1.3em;
}
.variation {
    display: flex;
    margin: 20px 0;
    flex-wrap: wrap;
    @media(max-width: 1199px) {
        flex-direction: column;
        flex-wrap: nowrap;
        padding-top: 43px;
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s;
        border: 1px solid rgba(196, 196, 196, 0.5);
    }
    &-open {
        max-height: 100vh;
    }
    &__item {
        margin-bottom: 10px;
        margin-right: 20px;
        @media(max-width: 1400px) {
            margin-right: 15px;
        }
        @media(max-width: 1199px) {
            text-align: center;
            margin-top: 10px;
            margin-right: 0;
        }
        &-checked {
            color: $main-color;
            cursor: pointer;
            font-size: 18px;
            line-height: 46px;
            font-weight: 700;
            cursor: pointer;
            width: 100%;
            height: 43px;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            .variation-open & {
                border-bottom: 1px solid rgba(196, 196, 196, 0.5);
            }
            &:after {
                content: "";
                background: url(../img/svg/arrow.svg);
                width: 12px;
                height: 5px;
                position: absolute;
                top: 20px;
                right: 20px;
                transform: rotate(180deg);
                transition: all 0.5s;
                .variation-open & {
                    transform: rotate(0deg);
                }
            }
            span {
                color: #c4c4c4;
            }
            @media(max-width: 1199px) {
                display: block;
            }
        }
    }
    &__label {
        color: #C4C4C4;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.1s;
        @media(max-width: 1199px) {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    &__input {
        display: none;
        &:checked+span {
            color: $main-color;
            @media(max-width: 1199px) {
                color: #C4C4C4;
            }
        }
    }
}
.checkbox {
    margin-right: 20px;
    margin-bottom: 20px;
    &__input {
        width: 0px;
        height: 0px;
        display: none;
        // &:before{
        // 	@extend .iconmoon;
        // 	content: '';
        // 	width: 100%;
        // 	height: 100%;
        // 	position: absolute;
        // 	top: 0;
        // 	left: 0;
        // 	z-index: -1;
        // 	border: 1px solid rgba(196, 196, 196, 0.5);
        // 	background: #fff;
        //     		z-index: 2;
        //     		cursor: pointer;
        // }
        // &:checked:before{
        // 	content: '\e900';
        // 	color: #17B057;
        // 	font-weight: bold;
        // 	font-size: 18px;
        // 			text-align: center;
        // 			line-height: 14px;
        // 	color: #17B057;
        // }
        &+label {
            padding-left: 28px;
            &:before {
                @extend .iconmoon;
                content: '';
                width: 20px;
                height: 20px;
                vertical-align: middle;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                border: 1px solid rgba(196, 196, 196, 0.5);
                background: #fff;
                z-index: 2;
                cursor: pointer;
            }
        }
        &:checked+label:before {
            content: '\e900';
            color: #17B057;
            font-weight: bold;
            font-size: 18px;
            text-align: center;
            line-height: 14px;
            color: #17B057;
        }
    }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
.quantity {
    position: relative;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
    border-radius: 40px;
    height: 21px;
    width: 80px;
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    &__row {
        display: flex;
        margin-bottom: 14px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &-input {
        padding: 0;
        margin: 0;
        width: 15px;
        text-align: center;
        font-size: 15px;
        &:focus {
            outline: 0;
        }
    }
    &-button {
        font-family: Montserrat Alternates, sans-serif;
        cursor: pointer;
        padding: 0 10px;
        text-align: center;
        color: $main-color;
        font-size: 22px;
        line-height: 21px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        &:before {
            content: '';
            height: 21px;
            width: 70px;
            border-radius: 40px;
            position: absolute;
            top: 0;
            z-index: -1;
            transition: all 0.3s;
        }
    }
    &-down {
        &:before {
            left: 0;
        }
        &:active:before {
            box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.07);
        }
    }
    &-up {
        &:before {
            right: 0;
        }
        &:active:before {
            box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.07);
        }
    }
}
.product__price {
    font-size: 36px;
    margin: 20px 0;
    @media (max-width: 767px), (max-height: 460px) {
        text-align: center;
        font-size: 18px;
        color: $text-color;
        font-weight: bold;
    }
    .text {
        position: absolute;
        top: 2px;
        left: 0;
    }
}
.characteristic {
    &__table {
        td:first-child {
            width: 50%;
        }
    }
}
.table-light {
    width: 100%;
    td {
        padding: 7px 0;
    }
}
.hoverzoom {
    display: inline-block;
    //overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    &__img {
        position: relative;
        width: 100%;
    }
    &__wrap {
        position: relative;
        display: inline-flex;
    }
}
.magnifier {
    position: absolute;
    opacity: 0;
    background: rgba(196, 196, 196, 0.4);
    border: 0.5px solid #FFFFFF;
    right: 0;
    bottom: 0;
    z-index: 5;
    @media (max-width: 991px), (max-height: 460px) {
        display: none;
    }
}
.zoom__image {
    width: calc(100vw / 12 * 4 - 10px);
    height: calc(100vw / 12 * 4 - 10px);
    overflow: hidden;
    position: absolute;
    left: 100%;
    top: 0;
    z-index: -1;
    opacity: 0;
    transition: all 0.5s;
    display: block;
    @media(max-width: 991px), (max-height: 460px) {
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        .zooming & {
            opacity: 1;
            z-index: 2;
        }
    }
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0px;
        height: 0px;
    }
    img {
        max-width: unset;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        @media(max-width: 991px), (max-height: 460px) {
            width: 200% !important;
            height: auto !important;
        }
    }
    &-active {
        z-index: 99;
        opacity: 1;
    }
}
.basket {
    height: 100vh;
    padding-right: 0px;
    display: flex;
    @media(max-width: 767px) {
        flex-wrap: wrap;
        height: auto;
    }
    &__left {
        width: 25vw;
        min-width: 300px;
        flex-shrink: 0;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        @media(max-width: 1024px) {
            min-width: 250px;
        }
        @media(max-width: 767px),
        (max-height: 460px) {
            width: 100%;
            min-width: unset;
            height: auto;
        }
    }
    &__sidebar {
        overflow: auto;
        width: 100%;
        height: 100%;
        padding: 40px 20px 0;
        -webkit-overflow-scrolling: touch;
        @media(max-width: 1024px) {
            padding: 20px 20px 0 10px;
        }
        @media(max-width: 767px),
        (max-height: 460px) {
            padding: 10px 20px 0;
        }
    }
    &__card {
        margin-bottom: 40px;
        @media(max-width: 767px), (max-height: 460px) {
            display: flex;
        }
        @media(max-width: 540px) {
            display: block;
        }
        .card {
            padding: 0;
            height: calc(100vw / 4 * 0.745);
            @media(max-width: 767px), (max-height: 460px) {
                width: 33.33%;
                height: unset;
            }
            @media(max-width: 540px) {
                width: 100%;
            }
        }
    }
    &__price {
        font-size: 15px;
        position: relative;
        margin-top: 10px;
        display: inline-block;
        line-height: 20px;
    }
    &__footer {
        padding: 20px;
        @media(max-width: 991px) {}
    }
    &__total {
        font-weight: bold;
        font-size: 18px;
        margin-left: 20px;
    }
    &__content {
        padding: 60px 40px 0;
        width: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        @media(max-width: 1024px) {
            padding: 40px;
        }
        @media(max-width: 767px),
        (max-height: 460px) {
            overflow: unset;
            padding: 20px;
        }
        @media(max-width: 540px) {
            .btn {
                margin-left: auto;
                margin-right: auto;
                display: block;
            }
        }
    }
    &__answer {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #fff;
        z-index: 5;
        margin: 0;
    }
}
.answer {
    &__wrap {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        font-weight: bold;
        font-size: 18px;
        line-height: 31px;
        text-align: center;
        padding: 0 120px 0 140px;
        @media(max-width: 767px) {
            padding: 0 50px;
        }
        @media(max-width: 540px) {
            padding: 0 20px;
        }
    }
}
.success {
    p {
        color: $main-color;
        width: 100%;
    }
}
.hint {
    width: 17px;
    height: 17px;
    background: #FFFFFF;
    border: 1px solid $main-color;
    border-radius: 40px;
    color: $main-color;
    text-align: center;
    line-height: 15px;
    display: inline-block;
    bottom: 3px;
    font-size: 12px;
    cursor: pointer;
    position: relative;
    order: 2;
    @media(max-width: 767px), (max-height: 460px) {
        display: none;
    }
    @media(max-width: 540px) {
        display: block;
    }
    &:before {
        content: "";
        width: 200%;
        height: 200%;
        position: absolute;
        top: -50%;
        left: -50%;
    }
    &.hint-active {
        &+.hint__content {
            padding: 20px 0;
            max-height: 700px;
            @media(max-width: 767px), (max-height: 460px) {
                padding: 0;
                max-height: unset;
            }
            @media(max-width: 540px) {
                padding: 10px 0;
                max-height: 700px;
            }
        }
    }
    &__content {
        width: 100%;
        padding: 0;
        max-height: 0;
        transition: all 0.5s ease-out;
        overflow: hidden;
        background: #fff;
        text-align: center;
        z-index: 4;
        @media(max-width: 767px), (max-height: 460px) {
            max-height: unset;
        }
        @media(max-width: 540px) {
            max-height: 0;
        }
    }
    &__title {
        color: $text-color;
        margin-bottom: 20px;
        text-align: left;
        line-height: 1.3;
        @media(max-width: 1024px) {
            margin-bottom: 10px;
        }
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: end;
        @media(max-width: 767px), (max-height: 460px) {
            width: 65%;
            padding: 0 0 0 20px;
        }
        @media(max-width: 540px) {
            padding: 0;
            width: 100%;
        }
        .basket__price {
            order: 2;
        }
    }
}
.count {
    &__item {
        line-height: 1.3;
        text-align: left;
        margin-bottom: 8px;
        padding-left: 40px;
        position: relative;
        color: $secondary-text-color;
        &:last-of-type {
            margin-bottom: 20px;
        }
        @media(max-width: 1024px) {
            padding-left: 28px;
        }
    }
    &-decor {
        width: 31px;
        height: 19px;
        background: #FFFFFF;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
        border-radius: 40px;
        text-align: center;
        margin-right: 10px;
        display: inline-block;
        line-height: 19px;
        vertical-align: text-bottom;
        position: absolute;
        left: 0;
        top: 2px;
        @media(max-width: 1024px) {
            width: 21px;
        }
    }
}
.form {
    &__row {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        @media (max-width: 991px) {
            flex-wrap: wrap;
        }
        @media (max-width: 767px) {
            flex-wrap: nowrap;
        }
        @media (max-width: 540px) {
            flex-wrap: wrap;
        }
    }
    &__desc {
        font-size: 11px;
        color: $secondary-text-color;
        @media(max-width: 991px) {
            padding: 5px 20px 0;
            line-height: 1.2;
            max-width: 325px;
        }
    }
}
#basket__form {
    transform: translateZ(0px) !important;
}
.input {
    z-index: 1;
    transform: translateZ(0px) !important;
    -webkit-user-select: text;
    -webkit-text-fill-color: $text-color;
    -webkit-opacity: 1;
    color: $text-color;
    font-family: inherit;
    width: 305px;
    max-width: 100%;
    height: 32px;
    border: 1px solid rgba(196, 196, 196, 0.5);
    background: transparent;
    line-height: 32px;
    margin-right: 20px;
    padding: 0 20px;
    position: relative;
    display: inline-block;
    transition: all 0.3s;
    padding-top: 5px;
    @media(max-width: 540px) {
        width: 100%;
    }
    &__wrap {
        position: relative;
        @media(max-width: 540px) {
            width: 100%;
        }
    }
    &__placeholder {
        color: #C4C4C4;
        position: absolute;
        white-space: nowrap;
        top: 5px;
        left: 10px;
        transition: all 0.3s;
        padding: 0 10px;
        background: #fff;
        z-index: -1;
    }
    &:focus,
    &:active {
        border-color: $main-color;
        &+.input__placeholder {
            color: $main-color;
            top: -6px;
            font-size: 11px;
            z-index: 1;
        }
    }
    &.input-focus+.input__placeholder {
        top: -6px;
        font-size: 11px;
        z-index: 1;
    }
    &.input-error {
        border-color: #ff1c1c;
    }
}
// input:-webkit-autofill {
//     -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
//     -webkit-text-fill-color: #999 !important; /* цвет текста */
//     color: #999 !important; /* цвет текста */
// }
textarea {
    &.input {
        height: auto;
        width: 100%;
        margin: 0;
    }
    &:focus {
        outline: none;
    }
}
.select {
    width: 305px;
    height: 32px;
    border: 1px solid rgba(196, 196, 196, 0.5);
    line-height: 32px;
    margin-right: 20px;
    position: relative;
    display: inline-block;
    @media(max-width: 540px) {
        width: 100%;
    }
    &-wrapper {
        position: relative;
        display: inline-block;
        user-select: none;
        @media(max-width: 540px) {
            width: 100%;
        }
    }
    &-wrapper select {
        display: none;
    }
    &-trigger {
        position: relative;
        display: block;
        background: #fff;
        padding: 0 20px;
        height: 100%;
        border-radius: 4px;
        cursor: pointer;
        color: $secondary-text-color;
        overflow: hidden;
        &:after {
            position: absolute;
            display: block;
            content: '';
            width: 12px;
            height: 5px;
            top: 45%;
            right: 15px;
            transform: rotate(180deg);
            background-image: url('../img/svg/arrow.svg');
            background-size: contain;
            transition: all 0.5s;
        }
    }
    &.opened .select-trigger:after {
        transform: rotate(0deg);
    }
    &.opened .custom-options {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transform: translateY(0);
    }
}
.custom-options {
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    min-width: 100%;
    margin: 0;
    border: 1px solid rgba(196, 196, 196, 0.5);
    box-sizing: border-box;
    background: #fff;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(-15px);
    z-index: 9;
    overflow: hidden;
    padding: 15px 0 5px;
}
.option-hover:before {
    background: #fff;
}
.custom-option {
    position: relative;
    display: block;
    padding: 0 20px;
    line-height: 20px;
    min-height: 20px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: all 0.1s;
}
.custom-option:last-of-type {
    border-bottom: 0;
}
.custom-option:hover,
.custom-option.selection {
    color: $main-color;
    font-size: 16px;
    padding-left: 15px;
    padding-right: 0;
    //transform: scale(1.05);
}
.custom-option.selection {
    display: none;
}
.review {
    &__form {
        margin: 0 0 60px;
    }
    &__input {
        width: 100%;
    }
    &__block {
        padding: 20px;
        margin: 0 0 20px;
        border: 1px solid rgba(196, 196, 196, 0.5);
        .rating__star {
            width: 16px;
            height: 16px;
            font-size: 16px;
            line-height: 16px;
        }
        .rating {
            margin-right: 0;
        }
    }
    &__subcomment{
        margin-left: 80px;
        margin-top: -10px;
        @media(max-width: 767px), (max-height: 460px) {
           margin-left: 65px;
        }
        @media(max-width: 475px) {
           margin-left: 40px;
        }
    }
    &__name {
        font-size: 15px;
        color: $text-color;
        display: inline-block;
        margin-right: auto;
    }
    &__answer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 5;
    }
}
$star_width: 24px;
$star_size: 24px;
.rating {
    width: auto;
    margin: 0;
    position: relative;
    display: inline-flex;
    flex-direction: row-reverse;
    vertical-align: middle;
    @media(max-width: 767px), (max-height: 460px) {
        margin-right: 40px;
    }
    &__star {
        @extend .iconmoon;
        display: inline-block;
        font-size: $star_size;
        color: #FBB202;
        width: $star_width;
        height: $star_width;
        line-height: $star_width;
        text-align: center;
        &:after {
            content: '\e903';
        }
        &-check~.rating__star:after,
        &-check:after {
            content: '\e902';
        }
    }
    &__input {
        position: absolute;
        width: $star_width;
        height: $star_width;
        margin: 0;
        cursor: pointer;
        opacity: 0;
        z-index: 5;
        &:checked~.rating__star:after,
        &:hover~.rating__star:after {
            content: '\e902';
        }
        @for $i from 0 through 5 {
            &:nth-of-type(#{$i + 1}) {
                right: #{$i * $star_width};
            }
        }
    }
}
#map {
    width: 100%;
    height: 100%;
}
.gmnoprint,
.gmnoprint {
    display: none;
}
.blog {
    &__row {
        @media(max-width: 767px), (max-height: 460px) {
            margin: 0;
            padding: 0 10px;
        }
    }
    &__item {
        display: block;
        width: 33.33%;
        padding: 0 20px;
        margin-bottom: 20px;
        &:hover {
            .blog__img {
                transform: scale(1.05);
            }
        }
        @media(max-width: 767px), (max-height: 460px) {
            padding: 0 10px;
        }
        @media(max-width: 567px),
        (max-height: 460px) {
            width: 100%;
            text-align: center;
            max-width: 350px;
            margin: 0 auto;
        }
    }
    &__image{
        width: 100%;
        padding-top: 157%;
        position: relative;
        margin: 0 auto 20px;
        @media(max-width: 567px),
        (max-height: 460px) {
            padding-top: 0;
        }
    }
    &__img {
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.3s;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; 
        @media(max-width: 567px),
        (max-height: 460px) {
            position: unset;
            height: auto;
        }
    }
}
.post {
    max-width: 880px;
    padding: 0 40px 40px;
    @media (max-height: 460px), (max-width: 767px) {
        padding: 0 20px 20px;
    }
    @media (max-width: 540px) {
        padding: 0 20px 10px;
    }
}
.pagination {
        margin: 40px auto 30px;
    text-align: center;
    &__arrow {
        background: transparent;
        border: none;
        cursor: pointer;
        background: url(../img/svg/arrow.svg);
        width: 15px;
        height: 6px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 5px;
    }
    &__prev {
        transform: rotate(-90deg);
    }
    &__next {
        transform: rotate(90deg);
    }
    &__list {
        display: inline-flex;
        margin: 0;
        list-style: none;
    }
    &__more {
        cursor: pointer;
        margin: 0 10px;
    }
    &__link {
        color: $main-color;
        width: 30px;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        border-radius: 50%;
        &-active {
            background: #FCFCFC;
        }
        &:hover {
            text-decoration: none;
            color: $main-color;
        }
    }
}