*, *:before, *:after {
	padding: 0;
	box-sizing: border-box;
	margin: 0;
	position: relative; }

img {
	height: auto;
	max-width: 100%; }

a, a:link, a:visited, a:hover, a:active, a:focus {
	text-decoration: none;
	outline: none;
	white-space: nowrap; }
a {
	color: inherit;
	cursor: pointer; }
input {
	outline: none;
	font-family: inherit;
	border: none; }

button {
	border: none;
	outline: none;
	background: transparent; }

ul {
	li {
		list-style: none; } }

