*::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  background: #e7e7e7;
  border-radius: 0;
}
*::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(163, 163, 163, .8);
}
*::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 0;
  background: rgba(163, 163, 163, .9);
  transition: background 0.2s ease;
}
*::-webkit-scrollbar-thumb:hover {
  background: rgba(163, 163, 163, 1);
}

/*ff*/
* {
  scrollbar-color:  rgba(163, 163, 163, 1) #e7e7e7;
   scrollbar-width: thin;
}

@-moz-document url-prefix(http://),url-prefix(https://) {
    scrollbar {
       -moz-appearance: none !important;
       background: rgb(0,255,0) !important;
    }
    thumb,scrollbarbutton {
       -moz-appearance: none !important;
       background-color: rgb(0,0,255) !important;
    }

    thumb:hover,scrollbarbutton:hover {
       -moz-appearance: none !important;
       background-color: rgb(255,0,0) !important;
    }
    scrollbarbutton {
       display: none !important;
    }
    scrollbar[orient="vertical"] {
      min-width: 15px !important;
    }
}

::-webkit-input-placeholder {color: #C4C4C4; font-family: 'Montserrat Alternates',sans-serif;}
::-moz-placeholder          {color: #C4C4C4; font-family: 'Montserrat Alternates',sans-serif;}
:-moz-placeholder           {color: #C4C4C4; font-family: 'Montserrat Alternates',sans-serif;}
:-ms-input-placeholder      {color: #C4C4C4; font-family: 'Montserrat Alternates',sans-serif;}


a, label{
  -webkit-tap-highlight-color: transparent;
}
body{
  -webkit-text-size-adjust: none;
}
/*input[type=text], input[type=submit], textarea{
 // -webkit-appearance: none;
} 
*/